<template>
  <div>
    <div class="propety">
      <div style="min-height: 600px">
        <el-form :model="data" ref="ListingForm" :rules="rules">
          <div class="pages">
            <div class="form_in">
              <!--bedrooms  -->
              <div>
                <div class="top-prodect" @click="bedroomShow = !bedroomShow">
                  <span class="title-text">Bedroom</span><i class="el-icon-arrow-down" v-if="bedroomShow"></i>
                  <i class="el-icon-arrow-right" v-if="!bedroomShow"></i>
                </div>
                <div v-if="bedroomShow">
                  <div class="set_width">
                    <div class="propety_row top_row">
                      <div class="propety_type">
                        <div class="propety_row top_row">
                          <div class="propety_type">
                            <p class="propety-title">How many Bedrooms</p>
                            <el-select v-model="data.bedroom_number" @change="bedroomNumberChange" placeholder>
                              <el-option v-for="item in 100" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-for="(item, index) in data.bedrooms" :key="index">
                    <div class="set_width" style="margin: 0">
                      <div>
                        <h3 class="room-small-title">
                          Bedroom&nbsp;{{ index + 1 }}
                        </h3>
                      </div>
                    </div>
                    <div class="check-row row" style="margin-top: 10px">

                    </div>
                    <div class="set_width" style="margin-left: 20px">
                      <div class="propety_level" style="max-width:590px;">
                        <el-form-item :prop="'bedrooms.' + index + '.floor_level'">
                          <p class="propety-title">Floor Level</p>
                          <el-select v-model="item.floor_level" placeholder="">
                            <el-option v-for="item in floorLevelList" :key="item.id" :label="item.name"
                              :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="propety_icon" v-for="(bed, i) in item.beds" :key="i">
                        <div class="propety_row">
                          <div class="propety_type property_bed">
                            <div class="propety_row">
                              <div class="propety_type">
                                <el-form-item :prop="'bedrooms.' + index + '.beds.' + i + '.type'">
                                  <p class="propety-title">Type of Bed</p>
                                  <el-select v-model="bed.type" placeholder="">
                                    <el-option v-for="item in bedTypeList" :key="item.id" :label="item.name"
                                      :value="item.id"></el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="propety_type">
                                <div class="propety_row bed_row">
                                  <el-form-item class="bed_number"
                                    :prop="'bedrooms.' + index + '.beds.' + i + '.number'">
                                    <p class="propety-title">Quantity</p>
                                    <el-select v-model="bed.number" placeholder="">
                                      <el-option v-for="(item, index) in 10" :key="index" :label="index" :value="index">
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                  <span @click="removeBed(item.beds, i)" class="remove-btn" v-show="i">
                                    <i class="el-icon-circle-close"></i>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="increase_btn" style="margin-left: 20px">
                      <a class="btn-add" @click="addBed(item.beds)">+ Add Bed Type</a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- bathroom -->
              <div>
                <div class="top-prodect" @click="bathroomShow = !bathroomShow">
                  <span class="title-text">Bathroom</span><i class="el-icon-arrow-down" v-if="bathroomShow"></i>
                  <i class="el-icon-arrow-right" v-if="!bathroomShow"></i>
                </div>
                <div v-if="bathroomShow">
                  <div class="set_width">
                    <div class="propety_row">
                      <div class="propety_type">
                        <div class="propety_row">
                          <div class="propety_type">
                            <p class="propety-title">How many Bathrooms</p>
                            <el-select v-model="data.bathroom_number" @change="bathroomNumberChange"
                              placeholder="Number of bedrooms">
                              <el-option v-for="item in 100" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                          </div>
                          <div class="propety_type">
                            <p class="propety-title">How many Half Bathrooms</p>
                            <el-select v-model="data.half_bathroom_number" placeholder="">
                              <el-option v-for="(item, index) in 101" :key="index" :label="index" :value="index">
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-for="(item, index) in data.bathrooms" :key="index">
                    <div class="set_width" style="margin-left: 20px">
                      <div>
                        <h3 class="room-small-title">
                          Bathroom&nbsp;{{ index + 1 }}
                        </h3>
                      </div>
                    </div>

                    <div class="check-row row" style="margin-top: 10px; margin-left: 40px; flex-direction: column;">
                      <div class="checkbox-item">
                        <el-checkbox v-model="item.bidet">Bidet</el-checkbox>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox v-model="item.combination_tub">Cobination Tub</el-checkbox>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox v-model="item.toilet">Toilet</el-checkbox>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox v-model="item.tub">Tub</el-checkbox>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox v-model="item.shower">Shower</el-checkbox>
                      </div>
                    </div>
                    <div class="set_width" style="margin-left: 40px">
                      <div class="propety_row">
                        <div class="propety_type">
                          <el-form-item :prop="'bathrooms.' + index + '.type'">
                            <p class="propety-title">Bathroom Type</p>
                            <el-select v-model="item.type" placeholder="">
                              <el-option v-for="item in bathroomTypeList" :key="item.id" :label="item.name"
                                :value="item.id"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                    </div>

                    <div class="relation" style="margin-left: 40px;">
                      <el-switch style="display: block" v-model="item.checked" active-color="#678993"
                        inactive-text="Private to a Bedroom?" @change="selectBedroom(item, index)">
                      </el-switch>
                    </div>

                    <div class="set_width" v-show="item.checked">
                      <div class="propety_row">
                        <div class="propety_type">
                          <div class="propety_row">
                            <div class="propety_type">
                              <el-form-item>
                                <p class="propety-title">
                                  Associated with which Bedroom?
                                </p>
                                <el-select v-model="item.index" @change="matchBedroom(index, item.index)"
                                  placeholder="">
                                  <el-option v-for="item in bedrooms" :key="item.id" :label="item.key" :value="item.id">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </el-form>
      </div>
      <div class="btn-save">
        <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="fullscreenLoading">
          <p class="row btn-row">
            <span>Save/Update</span><i class="el-icon-right"></i>
          </p>
        </el-button>
      </div>
    </div>
    <!-- 保存的按钮 -->
    <div class="propety_save" @click="save" v-if="btnFlag">
      <el-tooltip class="item" effect="dark" content="Save" placement="bottom">
        <img src="../../assets/img/save.png" alt />
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PropertyWeek from "./Property/PropertyWeek"
import { apiGetListing, apiCreateListing, apiUpdateListing, apiPublishListing, apiHideListing, apiDeactivateListing, apiUploadFile } from "../../API/api";
import { Minixs } from "../../js/mixins";
export default {
  components: { PropertyWeek },
  mixins: [Minixs],
  data() {
    return {
      loadingFile: false,
      bedroomShow: true,
      bathroomShow: true,
      showReason: false,
      deactivated_reason: "",
      fullscreenLoading: false,
      btnFlag: true,
      fileName: "Attached Files", // 文件名
      fileUrl: "", // 文件访问地址
      action: "hide",
      map: {}, // 用来存放房间和浴室的对应关系
      data: {
        bedroom_number: 1,
        bedrooms: [
          {
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          },
        ],
        bathroom_number: 1,
        half_bathroom_number: 0,
        bathrooms: [
          {
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          },
        ],

      },
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      availabilities: [],
    };
  },
  methods: {
    ...mapActions([
      "getAreas",
      "getPropertyPriorityList",
      "getACTypeList",
      "getCompanyList",
      "getHeatingTypeList",
      "getTVServiceList",
      "getCoffeeMakerTypeList",
      "getStoveTypeList",
      "getToastTypeList",
      "getGrillTypeList",
      "getPoolTypeList",
      "getFloorLevelList",
      "getBedTypeList",
      "getBathroomTypeList",
      "getTennistCourtList",
    ]),
    selectAction(val) {
      if (val === "deactivate") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    // 修改Action动作
    changeAction(res) {
      if (res.deleted_at) {
        this.action = "deactivate";
        this.deactivated_reason = res.deactivated_reason;
        this.showReason = true;
      } else if (res.publish) {
        this.action = "publish";
        this.showReason = false;
      } else {
        this.action = "hide";
        this.showReason = false;
      }
    },
    // 数据格式转换
    changeDate(val) {
      let res = Object.assign({}, val)
      // 转换枚举类型
      if (res.occupancy_tax_file) {
        this.fileName = res.occupancy_tax_file.file_name;
        this.fileUrl = res.occupancy_tax_file.url;
        res.occupancy_tax_file = res.occupancy_tax_file.occupancy_tax_file_uuid;
      }
      if (res.priority) res.priority = res.priority.id;
      if (res.area) res.area = res.area.id;
      if (res.ac_types) {
        let arr = []
        res.ac_types.forEach(item => {
          arr.push(item.id)
        });
        res.ac_types = arr
      };
      if (res.heating_type) res.heating_type = res.heating_type.id;
      if (res.tv_service) res.tv_service = res.tv_service.id;
      if (res.coffee_maker_type)
        res.coffee_maker_type = res.coffee_maker_type.id;
      if (res.stove_type) res.stove_type = res.stove_type.id;
      if (res.toaster_type) res.toaster_type = res.toaster_type.id;
      if (res.grill_type) res.grill_type = res.grill_type.id;
      if (res.pool_type) res.pool_type = res.pool_type.id;
      if (res.bedrooms.length === 0) {
        for (let i = 0; i < res.bedroom_number; i++) {
          res.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      } else {
        for (let i = 0; i < res.bedrooms.length; i++) {
          res.bedrooms[i].floor_level = res.bedrooms[i].floor_level.id;
          let beds = res.bedrooms[i].beds;
          for (let j = 0; j < beds.length; j++) {
            res.bedrooms[i].beds[j].type = beds[j].type.id;
          }
        }
      }

      for (let i = 0; i < res.bathrooms.length; i++) {
        res.bathrooms[i].type = res.bathrooms[i].type.id;
      }
      // 关联浴室和房间
      if (res.bathrooms.length === 0) {
        for (let i = 0; i < res.bathroom_number; i++) {
          res.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      } else {
        for (let i = 0; i < res.bathrooms.length; i++) {
          if (res.bathrooms[i].bedroom) {
            res.bathrooms[i]["checked"] = true;
            res.bedrooms.forEach((bedroom, index) => {
              if (res.bathrooms[i].bedroom === bedroom.bedroom_uuid) {
                res.bathrooms[i]["index"] = index;
                this.map[i] = index;
              }
            });
          } else {
            res.bathrooms[i]["checked"] = false;
            res.bathrooms[i]["index"] = "";
          }
        }
      }

      // 删除不能传空值的key
      delete res.homeowner;
      // 删除该页面不会用到的key
      delete res.images;
      delete res.availabilities;
      delete res.rates;
      delete res.requirement;
      return res;
    },
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.availabilities = res;
        setTimeout(() => {
          if (this.$refs.retaWeek) this.$refs.retaWeek.setRatesList()
        }, 3000);
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setPropertyInfor", true);
        this.$store.commit("setPropertyReuirement", true);
        this.$store.commit("setPropertyPaymentInformation", true);
        if (res.availabilities.length != 0 || res.rates.length != 0) {
          this.$store.commit("setPropertyCalendar", true);
        }
        if (res.images.length != 0) {
          this.$store.commit("setPropertyPhoto", true);
        }
        this.data = this.changeDate(res);
        this.changeAction(res);
      });
    },

    // 房间数量改变
    bedroomNumberChange(val) {
      if (val < this.data.bedrooms.length) {
        // 小于
        this.data.bedrooms = this.data.bedrooms.splice(0, val);
        // 删除 map 中对应的关联
        for (let key in this.map) {
          if (this.map[key] >= val) {
            delete this.map[key];
            // 浴室中关联的房间去掉
            this.data.bathrooms[key].checked = false;
            this.data.bathrooms[key].index = "";
          }
        }
      } else {
        let num = val - this.data.bedrooms.length;
        for (let i = 0; i < num; i++) {
          this.data.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      }
    },
    // 增加床位
    addBed(beds) {
      beds.push({ type: "", number: 0 });
    },
    // 删除床位
    removeBed(beds, i) {
      beds.splice(i, 1);
    },
    // 浴室数量改变
    bathroomNumberChange(val) {
      if (val < this.data.bathrooms.length) {
        // 小于
        this.data.bathrooms = this.data.bathrooms.splice(0, val);
        // 删除 map 中对应的关联
        for (let key in this.map) {
          if (key >= val) {
            delete this.map[key];
          }
        }
      } else {
        let num = val - this.data.bathrooms.length;
        for (let i = 0; i < num; i++) {
          this.data.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      }
    },
    // 是否勾选私有浴室
    selectBedroom(bathroom, index) {
      if (!bathroom.checked) {
        delete this.map[index];
        bathroom.index = "";
      }
    },
    // 选择关联的卧室
    matchBedroom(bathroomIndex, bedroomIndex) {
      // bathroomIndex 为浴室的索引，bedroomIndex 为房间的索引
      this.map[bathroomIndex] = bedroomIndex; // 以浴室的索引作为key，房间的索引作为value
    },
    save() {
      this.$refs.ListingForm.validate((valid) => {
        if (valid) {
          let listing = JSON.parse(JSON.stringify(this.data));
          Object.keys(this.map)
            .reverse()
            .forEach((key) => {
              // 反序删除bathrooms中的浴室
              let bedroom = listing.bedrooms[this.map[key]];
              if (bedroom.bathrooms) {
                // 房间已经有浴室对象
                bedroom.bathrooms.push(listing.bathrooms.splice(key, 1)[0]);
              } else {
                bedroom["bathrooms"] = listing.bathrooms.splice(key, 1);
              }
            });
          this.fullscreenLoading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateListing(this.$route.params.id, listing)
              .then(
                (res) => {
                  this.$store.commit(
                    "setListing",
                    JSON.parse(JSON.stringify(res))
                  );

                  this.$store.commit("setPropertyInfor", true);
                  if (res.availabilities.length != 0 || res.rates.length != 0) {
                    this.$store.commit("setPropertyCalendar", true);
                  }
                  if (res.images.length != 0) {
                    this.$store.commit("setPropertyPhoto", true);
                  }
                  this.data = this.changeDate(res);
                  if (this.action === "publish" && !res.publish) {
                    // 未发布才发布
                    return apiPublishListing(this.$route.params.id).then(
                      (res) => {
                        this.$router.push({
                          name: "property-bedroomsandbathrooms",
                          params: { id: res.listing_id },
                        });
                        this.$message.success("Listing publish successfully");
                      }
                    );
                  } else if (
                    this.action === "deactivate" &&
                    (!res.deleted_at ||
                      res.deactivated_reason != this.deactivated_reason)
                  ) {
                    // 未禁用才禁用
                    return apiDeactivateListing(res.listing_id, {
                      deactivated_reason: this.deactivated_reason,
                    }).then((res) => {
                      this.$router.push({
                        name: "property-bedroomsandbathrooms",
                        params: { id: res.listing_id },
                      });
                      this.$message.success("Listing deactivate successfully");
                    });
                  } else if (
                    this.action === "hide" &&
                    (res.publish || res.deleted_at)
                  ) {
                    // 发布或禁用才变为隐藏
                    return apiHideListing(res.listing_id).then((res) => {
                      this.$message.success("Listing hide successfully");
                      this.$router.push({
                        name: "property-bedroomsandbathrooms",
                        params: { id: res.listing_id },
                      });
                    });
                  } else {
                    this.$router.push({
                      name: "property-bedroomsandbathrooms",
                      params: { id: res.listing_id },
                    });
                    this.$message.success(
                      "Listing information successfully modified"
                    );
                  }
                },
                (err) => {
                  this.$message.error("Listing information modify fail");
                }
              )
              .catch((err) => {
                this.$message.error(err);
              }).finally(() => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    changeStatus(selector) {
      var anchor = this.$el.querySelector(selector); // 参数为要跳转到的元素id
      document.body.scrollTop = anchor.offsetTop; // chrome
      document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let scrollHeight = window.pageYOffset || document.body.scrollHeight;
      let height = document.documentElement.scrollHeight;
      if (scrollHeight > height - 1000) {
        this.btnFlag = false;
      } else {
        if (this.$route.params.id) {
          this.btnFlag = true;
        } else {
          this.btnFlag = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  computed: mapState({
    areas: (state) => state.areas,
    propertyPriorityList: (state) => state.enum.propertyPriorityList,
    acTypeList: (state) => state.enum.acTypeList,
    companyList: (state) => state.enum.companyList,
    heatingTypeList: (state) => state.enum.heatingTypeList,
    tvServiceList: (state) => state.enum.tvServiceList,
    coffeeMakerTypeList: (state) => state.enum.coffeeMakerTypeList,
    stoveTypeList: (state) => state.enum.stoveTypeList,
    toastTypeList: (state) => state.enum.toastTypeList,
    grillTypeList: (state) => state.enum.grillTypeList,
    poolTypeList: (state) => state.enum.poolTypeList,
    floorLevelList: (state) => state.enum.floorLevelList,
    bedTypeList: (state) => state.enum.bedTypeList,
    bathroomTypeList: (state) => state.enum.bathroomTypeList,
    tennisCourtType: (state) => state.enum.tennisCourtType,
    // 计算卧室的数量
    bedrooms() {
      return this.data.bedrooms.map((_, i) => {
        return {
          key: "Bedroom " + (i + 1),
          id: i,
        };
      });
    },
  }),
  created() {
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.propertyPriorityList.length === 0) {
      this.getPropertyPriorityList();
    }
    if (this.acTypeList.length === 0) {
      this.getACTypeList();
    }
    if (this.companyList.length === 0) {
      this.getCompanyList();
    }
    if (this.heatingTypeList.length === 0) {
      this.getHeatingTypeList();
    }
    if (this.tvServiceList.length === 0) {
      this.getTVServiceList();
    }
    if (this.coffeeMakerTypeList.length === 0) {
      this.getCoffeeMakerTypeList();
    }
    if (this.stoveTypeList.length === 0) {
      this.getStoveTypeList();
    }
    if (this.toastTypeList.length === 0) {
      this.getToastTypeList();
    }
    if (this.grillTypeList.length === 0) {
      this.getGrillTypeList();
    }
    if (this.poolTypeList.length === 0) {
      this.getPoolTypeList();
    }
    if (this.floorLevelList.length === 0) {
      this.getFloorLevelList();
    }
    if (this.bedTypeList.length === 0) {
      this.getBedTypeList();
    }
    if (this.bathroomTypeList.length === 0) {
      this.getBathroomTypeList();
    }
    if (this.tennisCourtType.length === 0) {
      this.getTennistCourtList();
    }
    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.changeDate(
      //     JSON.parse(JSON.stringify(this.$store.state.listing))
      //   );
      //   this.changeAction(this.data);
      // } else {
      this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./Property.scss";
</style>
